<template>
 <div class="flex justify-between">
  <div v-if="this.$route.name === 'ExpertStatisticsCallDurations'" class="rounded-md">
   <p class="text-sm text-gray-600 font-bold">{{ $t("waitingTimeRange") }}</p>
   <div class="flex items-center gap-2">
    <input v-model="waitingTimeRange" type="range" id="vol" name="vol" min="5" max="30" step="5" />
    <span class="text-xs text-gray-500 font-medium"> {{ waitingTimeRange }}(s) </span>
   </div>
   <!-- <Slider v-model="waitingTimeRange" :min="5" :max="30" :step="5" /> -->
  </div>
  <div
   class="-mt-2 pb-4 bg-white sm:pb-4"
   v-if="(totalAnswered >= 0 && totalUnanswered >= 0) || totalDuration >= 0"
  >
   <div class="flex justify-end">
    <div class="max-w-xl px-4 sm:px-6 lg:px-8">
     <div class="max-w-xl mx-auto">
      <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-1">
       <div class="flex flex-col border-b border-gray-100 p-1 text-center sm:border-0 sm:border-r">
        <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">
         {{ $t("avgDurationTotal") }}
        </dt>
        <dd class="order-2 text-sm font-extrabold text-gray-900">
         {{ formatSeconds(totalDuration) }}
        </dd>
       </div>
      </dl>
     </div>
    </div>
   </div>
  </div>
 </div>
 <div
  v-if="
   seriesForColumn[0] &&
   seriesForColumn[0].data &&
   seriesForColumn[0].data.length &&
   seriesForColumn[0].data.length >= 25
  "
 >
  <apexchart
   :width="`${seriesForColumn[0].data.length * 30}px`"
   height="600px"
   :options="chartOptions"
   :series="this.seriesForColumn ? this.seriesForColumn : series"
  ></apexchart>
 </div>
 <div v-else>
  <apexchart
   height="600px"
   :options="chartOptions"
   :series="this.seriesForColumn ? this.seriesForColumn : series"
  ></apexchart>
 </div>
</template>

<script>
export default {
 name: "BasicColumnChart",
 props: [
  "seriesForColumn",
  "categoriesForColumn",
  "width",
  "height",
  "colors",
  "title",
  "totalAnswered",
  "totalUnanswered",
  "totalDuration",
  "waitingTime",
 ],
 data() {
  return {
   waitingTimeRange: 20,
   series: [],
   chartOptions: {
    title: {
     text: this.title,
     align: "center",
     margin: 10,
     offsetX: 0,
     offsetY: 0,
     floating: false,
     style: {
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: undefined,
      color: "#263238",
     },
    },
    noData: {
     text: "No data",
     align: "center",
     verticalAlign: "middle",
     offsetX: 0,
     offsetY: 0,
     style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
     },
    },

    colors: ["#97BAA6", "#E34B5F", "#1E7889"],
    chart: {
     type: "bar",
    },
    plotOptions: {
     bar: {
      horizontal: false,
      columnWidth: "70%",
      endingShape: "rounded",
      dataLabels: {
       position: "top", // top, center, bottom
      },
     },
    },
    dataLabels: {
     enabled: true,
     formatter: function (val) {
      if (val >= 0) {
       return val + "%";
      }
     },
     offsetY: -20,
     style: {
      fontSize: "10px",
      colors: ["#304758"],
     },
    },
    xaxis: {
     categories: this.categoriesForColumn ? this.categoriesForColumn : [],
     position: "bottom",
     offsetY: -5,
     axisBorder: {
      show: false,
     },
     axisTicks: {
      show: false,
     },
     crosshairs: {
      fill: {
       type: "gradient",
       gradient: {
        colorFrom: "#D8E3F0",
        colorTo: "#BED1E6",
        stops: [0, 100],
        opacityFrom: 0.4,
        opacityTo: 0.5,
       },
      },
     },
     tooltip: {
      enabled: true,
     },
     labels: {
      rotate: -75,
      rotateAlways: true,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: undefined,
      style: {
       colors: [],
       fontSize: "10px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      /**
       * Allows users to apply a custom formatter function to x-axis labels.
       *
       * @param { String } value - The default value generated
       * @param { Number } timestamp - In a datetime series, this is the raw timestamp
       * @param { object } contains dateFormatter for datetime x-axis
       */
      formatter: function (value, timestamp, opts) {
       return value;
       if (value && value.length >= 6) {
        let finalValue = "";
        let year = "";
        let month = "";
        let day = "";
        if (value.length > 0) {
         year = value.slice(0, 4);
         month = value.slice(4, 6);
         day = value.slice(6, 8);
        }
        finalValue = day + "/" + month + "/" + year;
        return finalValue;
       } else {
        return value;
       }
      },
     },
    },
    yaxis: {
     show: false,
     title: {
      text: "duration (min:s)",
     },
     labels: {
      formatter: function (value) {
       if (value >= 0) {
        return value + "%";
       }
      },
     },
    },
   },
  };
 },
 methods: {
  formatSeconds(value) {
   var minutes = Math.floor(value / 60);
   var seconds = value - minutes * 60;
   return minutes + "(min)" + ":" + seconds + "(s)";
  },
 },
 mounted() {
  this.$emit("loading", false);
  this.waitingTime
   ? (this.waitingTimeRange = this.waitingTime)
   : (this.waitingTimeRange = this.waitingTimeRange);
 },
 watch: {
  waitingTimeRange: function (val) {
   console.log("file: PbxDashboardSelector.vue:974 ~ val", val);
   this.$emit("waitingTimeRange", val);
  },
 },
};
</script>

<style></style>
